const redirectionTld = {

	init: function (API_KEY, PAGE) {

		const URL_GEOPLUGIN = `https://ssl.geoplugin.net/json.gp?k=${API_KEY}&jsoncallback=`;

		fetch(URL_GEOPLUGIN).then(
			data => data.json()
		).then(function (data) {
			/// URL API
			const url = new URL(window.location.href);
			let country = data.geoplugin_countryCode,
				paramCanada = url.searchParams.get('canada'),
				helpers = require('common/helpers');
			/// Paramètre canada
			/// ➞ permet à TCA d'accéder au .com
			if (paramCanada !== null && paramCanada !== "" && paramCanada === "ok") {
				helpers.setCookie('CanadaOk', 'ok', 365);
			}
			/// Si le paramètre canada est vide, on delete le cookie
			else if (paramCanada !== null && paramCanada === "") {
				helpers.deleteCookie('CanadaOk');
			}

			let RedirectCanada = helpers.getCookie('CanadaOk');

			/// CANADA
			/// S'il n'y a pas le cookie CanadaOk ET que geoplugin renvoie CA ET que l'url contient terdav.com
			/// on est redirigé sur TCA
			if (RedirectCanada === null || RedirectCanada === "" || RedirectCanada !== "ok") {
				const exclusions = ["inscription", "relanceoption", "relancedevis", "relancesolde", "paiementlibre"];
				// Test // ?testCanada=true
				// if (!exclusions.includes(PAGE) && url.searchParams.get('testCanada')) {
				// 	let url = window.location.href.replace(".com", ".ca");
				// 	window.location.href = "https://www.terdav.ca";
				// }
				if (country == 'CA' && window.location.href.includes('terdav.com')) {
					const exclusions = ["inscription", "relanceoption", "relancedevis", "relancesolde", "paiementlibre", "account", "espaceclient"];

					if (!exclusions.includes(PAGE)) {
						let url = window.location.href.replace(".com", ".ca");
						window.location.href = url;
					}
				}
			}

			/// BELGIQUE
			/// Si le geoplugin renvoie BE ET que l'url contient terdav.com
			/// on est redirigé sur terdav.be
			if (country == 'BE' && window.location.href.includes('terdav.com')) {
				let url = window.location.href.replace(".com", ".be");
				window.location.href = url;
			}

			/// Log
			/// pour débuger
			const getURLParameters = url => (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
				(a, v) => (
					(a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a
				), {}
			);
			const isStringOrObjectOrArrayEmpty = val => val == null || !(Object.keys(val) || val).length; // bool | number => true
			const testGeoplugin = getURLParameters(window.location.href).geoloc;
			if (!isStringOrObjectOrArrayEmpty(testGeoplugin)) {
				switch (testGeoplugin) {
				case 'data':
					console.log(data);
					break;
				case 'cookie':
					console.log(`Le cookie canada vaut ${helpers.getCookie("CanadaOk")}.`)
					helpers.getCookie("CanadaOk") === 'ok' ? console.log(`Il est possible d'accèder à TDV depuis le Canada`) : console.log(`Il est impossible d'accèder à TDV depuis le Canada`);
					break;
				case 'redirect':
					console.log(`Les conditions pour la redirection : \n Country : ${country == 'CA'} \n URL : ${window.location.href.includes('terdav.com')}`);
					helpers.getCookie("CanadaOk") === 'ok' ? console.log(`Le cookie est présent. Il est possible d'accèder à TDV depuis le Canada`) : console.log(`Le cookie n'est pas présent. Il est impossible d'accèder à TDV depuis le Canada`);
					break;
				default:
					console.info('La valeur du paramètre geoloc est incorrecte.')
				}
			}
		}).catch(function (error) {
			console.error(error)
		})
	}
}

module.exports = redirectionTld;